/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import Media from "react-md/lib/Media/Media";
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slider from "react-slick";

import Layout from '../../layout';

const settings = {
  dots: false,
  arrows: false,
  fade: true,
  autoplay: true,
  pauseOnHover: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1
};

const PrimaryProgram = () => (
  <StaticQuery
    query={graphql`
      query {
        Image1: file(relativePath: { eq: "11.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        Image2: file(relativePath: { eq: "15.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        Image3: file(relativePath: { eq: "16.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Grid stacked>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Slider {...settings}>
              <Card raised="true">
                <Media>
                  <Img fluid={data.Image1.childImageSharp.fluid} />
                </Media>
              </Card>
              <Card raised="true">
                <Media>
                  <Img fluid={data.Image2.childImageSharp.fluid} />
                </Media>
              </Card>
              <Card raised="true">
                <Media>
                  <Img fluid={data.Image3.childImageSharp.fluid} />
                </Media>
              </Card>
            </Slider>
          </Cell>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Card>
              <CardTitle title="Pre-Primary Program (3 years to 6 years)" />
              <CardText>
                <p className="md-text-justify">
                  Our goal for children at this stage is to help them develop harmoniously in
                  their physical, social, cognitive, and academic domains. We also help children
                  to increase their concentration skills and to refine, categorize, and expand
                  their previous knowledge.
                </p>
                <p className="md-text-justify">
                  The 3-to 6-year-old child needs freedom to move and to use his or her hands
                  to learn. The hands are the tools that help the young child learn at this age. All
                  experiences must be concrete and allow for manipulation of materials. Most of
                  the presentations of materials are given to individual children because the
                  child is still self-centered. It is not until the age of 4 1/2 or 5 that children
                  work more in group activities, except for Language activities.
                </p>
                <p className="md-text-justify">
                  Above all, teachers and staff work together to create a warm, safe and
                  nurturing environment for each child. There is a focus on modeling and
                  interacting with respect, courtesy, and compassion for every individual.
                  Teachers carefully guide children to navigate social situations with an
                  emphasis on the safety and well-being of all members of the group. Through
                  interactive games, children learn the courteous language and gracious actions
                  necessary to navigate the classroom and various social situations like how to
                  greet a guest, how to invite a friend to play, or even how to carefully push in a
                  chair! These vignettes of various social situations give children information
                  about how to handle situations at school and in the real world.
                </p>
                <h2 className="md-headline">Montessori Classroom Environment</h2>
                <p className="md-text-justify">
                  <font className="md-title">Practical Life</font>
                  <br />
                  These activities allow the child to practice everyday skills that help with the
                  development of independence and Executive Functioning skills through
                  activities such as the “zipper frame,” sweeping, polishing, washing dishes,
                  caring for plants, preparing food etc. All of these activities give the child the
                  opportunity to become proficient with self-help skills. They also significantly
                  contribute to the development of the child’s concentration, refinement of fine
                  and gross motor skills, and the development of self-esteem as they accomplish
                  tasks they see done around them by adults and contribute to their classroom
                  community.
                </p>
                <p className="md-text-justify">
                  <font className="md-title">Sensorial Materials</font>
                  <br />
                  These materials refine the child’s senses, which are still developing. Materials
                  requiring tactile, auditory, olfactory, gustatory, and visual discrimination of
                  size, shape, and color are used to enhance the refinement of each sense. In
                  addition, these give geometric experiences and vocabulary. Sensorial
                  materials are also used in other areas of the classroom, such as the language
                  area – to provide the qualitative vocabulary (i.e. rough, smooth, thick, thin) as
                  well as geometric terminology to teach about triangles, angles, and different
                  two and three-dimensional shapes. We also use sensorial materials in the
                  study of biology with the study of leaves, parts of plants, flowers, and animals.
                </p>
                <p className="md-text-justify">
                  <font className="md-title">Language Materials</font>
                  <br />
                  The young child is fascinated by language. We offer children plenty of
                  opportunities to acquire new vocabulary, giving the exact name of anything
                  that is of interest to them: bugs, insects, flowers, trucks, etc. We have a
                  phonetic approach to reading, which is teaching the sounds of the letters
                  instead of just the names. This enables the child to blend sounds together and
                  read more easily. From learning phonics, we will guide the child to learn
                  phonograms, combine letters to construct words, decode sounds to read
                  words, and learn sight words. Through interactive games, children experience
                  the role of various parts of speech in a phrase and the importance of word
                  order and grammar in sentences. Through the Language curriculum, children
                  have an opportunity to explore self-expression.
                </p>
                <p className="md-text-justify">
                  <font className="md-title">Mathematic Material</font>
                  <br />
                  All of the materials in this area are concrete representations of the abstract
                  concept they are designed to teach. The child manipulates rods, beads,
                  spindles, etc. to understand the concept of numbers one through ten, the
                  decimal system (units, tens, hundreds, and thousands), and all four
                  mathematic operations. By the end of the third year in this classroom,
                  children usually start memorizing math facts and have gained a solid
                  understanding of the four operations. Fractions are also presented and
                  explored with concrete materials.
                </p>
                <p className="md-text-justify">
                  <font className="md-title">Cultural Subjects</font>
                  <br />
                  Geography, History, Biology, Botany, Zoology, Art and Music are presented as
                  extensions of the sensorial and language activities. We introduce children to
                  geography through puzzle maps, pictures, stories, cuisine, and artifacts
                  representing a specific country or continent. Music and art are also integrated
                  into the Children’s House program. In addition to integration through
                  Montessori Materials such as the Bells, children engage in music activities
                  using rhythm, movement, songs, and listening activities. Children learn to
                  match pitch and may begin exploring the basics of reading and writing music.
                  Children are exposed to art through various concrete experiences that focus
                  on experimenting with different media. The fine arts program introduces
                  children to the basic building blocks of visual art through media exploration
                  and also introduces them to art appreciation.
                </p>
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </Layout>
    )}
  />
)

export default PrimaryProgram;
